<template>
	<div style="height: 100%;">
		<router-view />
	</div>
</template>

<script>
	export default {}
</script>

<style lang="scss" scoped></style>
