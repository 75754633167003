/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from "vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import App from "./App.vue";
import DefaultLayout from "./layouts/Default.vue";
import DefaultNotLayout from "./layouts/DefaultNot.vue";
import DashboardLayout from "./layouts/Dashboard.vue";
import DashboardFooter from "./layouts/DashboardFooter.vue";
import DashboardRTLLayout from "./layouts/DashboardRTL.vue";
import router from "./router";
import store from "./store";
// import './plugins/click-away'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-default/index.css'
 Vue.use(ElementUI)

import "./scss/app.scss";
import "./assets/fonts/fonts.css";

import { http } from "./api/api";
Vue.prototype.$http = http;

import { BASE_API } from "../env";
Vue.prototype.$BASE_API = BASE_API;

// 函数防抖
Vue.directive("debounce", {
  bind(el, binding) {
    let type = binding.arg;
    let fn = binding.value[0];
    let time = binding.value[1] || 300;
    let immediate = binding.value[2] || false;
    let debounce = (callback, time, immediate) => {
      let timer = null;
      return function () {
        if (timer) clearTimeout(timer);
        if (immediate) {
          let callNow = !timer;
          timer = setTimeout(() => {
            timer = null;
          }, time);
          if (callNow) callback.apply(this, arguments);
        } else {
          timer = setTimeout(() => {
            callback.apply(this, arguments);
          }, time);
        }
      };
    };
    el.addEventListener(
      type,
      debounce(
        function () {
          if (type === "scroll") {
            if (el.scrollHeight - el.scrollTop - el.clientHeight < 1) {
              fn();
            }
          } else {
            fn();
          }
        },
        time,
        immediate
      )
    );
  },
});

import VMdEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import Prism from "prismjs";

// main.js
import VueCropper from "vue-cropper";
Vue.use(VueCropper);

VMdEditor.use(vuepressTheme, {
  Prism,
});

Vue.use(VMdEditor);

Vue.use(Antd);

import waterfall from "vue-waterfall2";
Vue.use(waterfall);

Vue.config.productionTip = false;

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-default-not", DefaultNotLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-footer", DashboardFooter);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

Vue.directive({
  inserted: function (el, binding) {
    document.title = el.dataset.title;
  },
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
