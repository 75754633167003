/**
 * 验证手机号
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
const checkTelPhone = (rule, value, callback) => {
  !value && callback();
  const reg = /^1[3-9]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("手机号输入格式有误"));
  } else {
    callback();
  }
};

/**
 * 验证密码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
const checkPassword = (rule, value, callback) => {
  !value && callback();
  const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])[\w!@#$%^&*()-+=<>?/\[\]{}|~]{6,20}$/;
  if (!reg.test(value)) {
    callback("密码格式有误");
  } else {
    callback();
  }
};

/**
 * 比较两次密码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @param {*} params
 */
const checkAgainPassword = (rule, value, callback, params) => {
  !value && callback();
  if (params == value) {
    callback();
  } else {
    callback("两次密码不一致");
  }
};

/**
 * 验证身份证号码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
const checkCard = (rule, value, callback) => {
  !value && callback();
  //   const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])[\w!@#$%^&*()-+=<>?/\[\]{}|~]{6,20}$/;
  //   if (!reg.test(value)) {
  //     callback("密码格式有误");
  //   } else {
  //     callback();
  //   }
  let _IDRe18 =
    /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  let _IDre15 =
    /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
  // 校验身份证：
  if (_IDRe18.test(idVal) || _IDre15.test(idVal)) {
    callback("验证通过");
  } else {
    callback("身份证号码格式有误");
  }
};

/**
 * 验证真实姓名
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */

// let reg = /^[\u4E00-\u9FA5]{2,10}(·[\u4E00-\u9FA5]{2,10}){0,2}$/;
const realName = (rule, value, callback) => {
  !value && callback();
  //   const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])[\w!@#$%^&*()-+=<>?/\[\]{}|~]{6,20}$/;
  //   if (!reg.test(value)) {
  //     callback("密码格式有误");
  //   } else {
  //     callback();
  //   }
  let _IDRe18 =
    /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  let _IDre15 =
    /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;

  let reg = /^[\u4E00-\u9FA5]{2,10}(·[\u4E00-\u9FA5]{2,10}){0,2}$/;
  // 校验身份证：
  if (reg.test(idVal)) {
    callback("验证通过");
  } else {
    callback("真实姓名格式有误");
  }
};
/**
 * 验证支付宝账号
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */

// /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/
const zfbAccount = (rule, value, callback) => {
  !value && callback();
  //   const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])[\w!@#$%^&*()-+=<>?/\[\]{}|~]{6,20}$/;
  //   if (!reg.test(value)) {
  //     callback("密码格式有误");
  //   } else {
  //     callback();
  //   }
  let reg =
    /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/;
  // 校验身份证：
  if (reg.test(idVal)) {
    callback("验证通过");
  } else {
    callback("支付宝账号格式有误");
    this.$message.success("支付宝账号格式有误");
  }
};

export {
  checkTelPhone,
  checkPassword,
  checkAgainPassword,
  checkCard,
  realName,
  zfbAccount,
};
