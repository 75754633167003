// 使用示例
import { request } from "@/utils/request";

const apiList = {
	// 公共
	common: {
		index: {
			url: "/addons/chatgpt/web/index",
			method: "GET",
			desc: "首页数据",
		},
		statistic: {
			url: "/addons/chatgpt/web/index",
			method: "GET",
			desc: "统计数据",
		},
		init: {
			url: "/addons/chatgpt/index/init",
			method: "GET",
			desc: "初始化",
		},
		upload: {
			url: "/addons/chatgpt/index/upload",
			method: "POST",
			desc: "上传图片",
		},
		// 友情链接 addons/chatgpt/index/getlink
		getlink: {
			url: "addons/chatgpt/index/getlink",
			method: "POST",
			desc: "友情链接",
		},
	},
	// 登录
	login: {
		wxLogin: {
			url: "/addons/chatgpt/user/qrcode",
			method: "GET",
			desc: "公众号登录",
		},
		wxVerify: {
			url: "/addons/chatgpt/user/verify",
			method: "GET",
			timeout: 1000 * 100,
			desc: "验证授权登录",
		},
		// bindMobile  register
		wxBind: {
			url: "/addons/chatgpt/user/register",
			method: "POST",
			desc: "绑定手机号",
		},
		bindMobile: {
			url: "/addons/chatgpt/user/bindMobile",
			method: "POST",
			desc: "绑定手机号",
		},
		logout: {
			// /addons/chatgpt/user/logout
			url: "/addons/chatgpt/user/logout",
			method: "GET",
			desc: "退出登录",
		},
		sendCode: {
			url: "/addons/chatgpt/sms/send",
			method: "POST",
			desc: "发送验证码",
		},
		smsLogin: {
			url: "/addons/chatgpt/user/smsLogin",
			method: "POST",
			desc: "验证码登录/注册",
		},
		smsRegister: {
			url: "/addons/chatgpt/user/smsRegister",
			method: "POST",
			desc: "手机号注册",
		},
		accountLogin: {
			url: "/addons/chatgpt/user/accountLogin",
			method: "POST",
			desc: "账号密码登录",
		},
		forgotPassword: {
			url: "/addons/chatgpt/user/forgotPwd",
			method: "POST",
			desc: "找回密码",
		},
	},
	// 案例
	case: {
		classify: {
			url: "/addons/chatgpt/archives/cotegory",
			method: "GET",
			desc: "分类",
		},
		list: {
			url: "/addons/chatgpt/archives/index",
			method: "GET",
			desc: "列表",
		},
		detail: {
			url: "/addons/chatgpt/archives/show",
			method: "GET",
			desc: "详情",
		},
	},
	// 用户
	user: {
		info: {
			url: "/addons/chatgpt/user/index",
			method: "GET",
			desc: "用户信息",
		},
		// 修改头像 addons/chatgpt/user/profile
		profile: {
			url: "addons/chatgpt/user/profile",
			method: "POST",
			desc: "修改头像",
		},

		// 提现查询 addons/chatgpt/user_bank/info?type=wechat
		userBank: {
			url: "addons/chatgpt/user_bank/info",
			method: "GET",
			desc: "提现",
		},

		// 提现申请 addons/chatgpt/user_bank/edit
		edit: {
			url: "addons/chatgpt/user_bank/edit",
			method: "POST",
			desc: "提现",
		},
		// addons/chatgpt/user_wallet_apply/apply
		apply: {
			url: "addons/chatgpt/user_wallet_apply/apply",
			method: "POST",
			desc: "提现",
		},
		// 实名认证 addons/chatgpt/user/idenauthen
		idenauthen: {
			url: "addons/chatgpt/user/idenauthen",
			method: "POST",
			desc: "实名认证",
		},
		/***********************朋友圈*******************************/
		// 发布朋友圈  addons/chatgpt/user_friend/release
		release: {
			url: "addons/chatgpt/user_friend/release",
			method: "POST",
			desc: "发布朋友圈",
		},
		// 朋友圈  addons/chatgpt/user_friend/list
		friendList: {
			url: "addons/chatgpt/user_friend/list",
			method: "POST",
			desc: "朋友圈",
		},
		// 点赞  addons/chatgpt/user_friend/set_like
		set_like: {
			url: "addons/chatgpt/user_friend/set_like",
			method: "POST",
			desc: "点赞",
		},
		// 取消点赞 addons/chatgpt/user_friend/cancel_like
		cancel_like: {
			url: "addons/chatgpt/user_friend/cancel_like",
			method: "POST",
			desc: "取消点赞",
		},
		// 删除朋友圈 addons/chatgpt/user_friend/del_friend
		del_friend: {
			url: "addons/chatgpt/user_friend/del_friend",
			method: "POST",
			desc: "删除朋友圈",
		},
		//查看朋友圈详情 addons/chatgpt/user_friend/friend_info
		friend_info: {
			url: "addons/chatgpt/user_friend/friend_info",
			method: "POST",
			desc: "查看朋友圈详情",
		},
		// addons/chatgpt/user_friend/rule_list
		//单个朋友圈 addons/chatgpt/user_friend/rule_list
		ruleList: {
			url: "addons/chatgpt/user_friend/rule_list",
			method: "POST",
			desc: "单个朋友圈",
		},
		// 对话过的角色 addons/chatgpt/group/ruleMsg
		ruleMsg: {
			url: "addons/chatgpt/group/ruleMsg",
			method: "POST",
			desc: "对话角色",
		},
		//聊天表情包 addons/chatgpt/index/getEmote
		getEmote: {
			url: "addons/chatgpt/index/getEmote",
			method: "POST",
			desc: "聊天表情包",
		},

		// 性格addons/chatgpt/draw/getCharacter
		getCharacter: {
			url: "addons/chatgpt/draw/getCharacter",
			method: "GET",
			desc: "性格",
		},

		//声线设置 addons/chatgpt/txtts/ttsRole
		getAudio: {
			url: "addons/chatgpt/txtts/ttsRole",
			method: "POST",
			desc: "声线设置",
		},
		// 声线配置 addons/chatgpt/txtts/voiceConfig
		voiceConfig: {
			url: "addons/chatgpt/txtts/voiceConfig",
			method: "POST",
			desc: "声线设置",
		},
		// 签到状态 addons/chatgpt/user/checkSign
		checkSign: {
			url: "addons/chatgpt/user/checkSign",
			method: "POST",
			desc: "签到状态",
		},
		//签到 addons/chatgpt/user/userSign
		userSign: {
			url: "addons/chatgpt/user/userSign",
			method: "POST",
			desc: "签到",
		},
	},
	// 对话
	chat: {
		create: {
			url: "/addons/chatgpt/group/saveGroup",
			method: "GET",
			desc: "新建会话",
		},
		delete: {
			url: "/addons/chatgpt/group/delGroup",
			method: "GET",
			desc: "删除会话",
		},
		list: {
			url: "/addons/chatgpt/group/getGroupList",
			method: "GET",
			desc: "会话列表",
		},
		history: {
			url: "/addons/chatgpt/web/history",
			method: "GET",
			desc: "聊天历史记录",
		},
		sendText: {
			url: "/addons/chatgpt/web/sendText",
			method: "fetch",
			desc: "获取数据流",
		},
		//腾讯云链接 addons/chatgpt/index/get_wssurl_asr
		getWssurlAsr: {
			url: "addons/chatgpt/index/get_wssurl_asr",
			method: "POST",
			desc: "腾讯云链接",
		},
	},
	// 创作
	write: {
		first: {
			url: "/addons/chatgpt/write/topic",
			method: "GET",
			desc: "一级分类",
		},
		second: {
			url: "/addons/chatgpt/write/prompts",
			method: "GET",
			desc: "二级分类",
		},
		detail: {
			url: "/addons/chatgpt/write/prompt",
			method: "GET",
			desc: "分类详情",
		},
		collectList: {
			url: "/addons/chatgpt/write/vote_prompts",
			method: "GET",
			desc: "收藏的二级分类列表",
		},
		collect: {
			url: "/addons/chatgpt/write/vote_prompt",
			method: "GET",
			desc: "收藏/取消收藏二级分类",
		},
		record: {
			url: "/addons/chatgpt/web/history_write",
			method: "get",
			desc: "创作记录",
		},
	},
	// 角色
	role: {
		first: {
			url: "/addons/chatgpt/rule/topic",
			method: "get",
			desc: "一级分类",
		},
		second: {
			url: "addons/chatgpt/rule/prompts",
			method: "GET",
			desc: "二级分类",
		},
		detail: {
			url: "/addons/chatgpt/rule/prompt",
			method: "GET",
			desc: "分类详情",
		},
		collectList: {
			url: "/addons/chatgpt/rule/vote_prompts",
			method: "GET",
			desc: "收藏的二级分类列表",
		},
		collect: {
			url: "/addons/chatgpt/rule/vote_prompt",
			method: "GET",
			desc: "收藏/取消收藏二级分类",
		},
		create: {
			url: "/addons/chatgpt/web/rule_group",
			method: "get",
			desc: "创建角色会话",
		},
		history: {
			url: "/addons/chatgpt/web/history_rule",
			method: "get",
			desc: "角色历史记录",
		},
		indexRoleModule: {
			url: "addons/chatgpt/index/indexRoleModule",
			method: "post",
			desc: "网红模板",
		},
	},
	// 分销
	place: {
		data: {
			url: "/addons/chatgpt/user/userData",
			method: "get",
			desc: "个人数据",
		},
		team: {
			url: "/addons/chatgpt/reseller/user",
			method: "get",
			desc: "团队",
		},
		brokerage: {
			url: "/addons/chatgpt/reseller/log",
			method: "get",
			desc: "分销记录",
		},
	},
	// 充值
	recharge: {
		integralList: {
			url: "/addons/chatgpt/usable/index",
			method: "get",
			desc: "积分类别",
		},
		memberList: {
			url: "/addons/chatgpt/vip/index",
			method: "get",
			desc: "会员类别",
		},
		dealerList: {
			url: "/addons/chatgpt/reseller/index",
			method: "get",
			desc: "经销商类别",
		},
		createIntegralOrder: {
			url: "/addons/chatgpt/usable/recharge",
			method: "post",
			desc: "创建积分充值订单",
		},
		createMemberOrder: {
			url: "/addons/chatgpt/vip_order/recharge",
			method: "post",
			desc: "创建会员订单",
		},
		createDealerOrder: {
			url: "/addons/chatgpt/reseller/recharge",
			method: "post",
			desc: "创建经销商订单",
		},
		pay: {
			url: "/addons/chatgpt/pay/prepay",
			method: "post",
			desc: "拉起支付",
		},
		queryPayStatus: {
			url: "/addons/chatgpt/pay/checkPay",
			method: "post",
			desc: "查询支付状态",
		},
		// 卡密兑换 /addons/chatgpt/cryptocard/decrypt_card
		decryptCard: {
			url: "/addons/chatgpt/cryptocard/decrypt_card",
			method: "GET",
			desc: "卡密兑换",
		},
	},
	// 绘图
	drawing: {
		first: {
			url: "/addons/chatgpt/draw/prompts",
			method: "GET",
			desc: "绘图分类",
		},
		config: {
			url: "/addons/chatgpt/draw/prompt_config",
			method: "GET",
			desc: "配置表",
		},
		create: {
			url: "/addons/chatgpt/draw/text2img",
			method: "POST",
			desc: "生成图片",
		},
		result: {
			url: "/addons/chatgpt/draw/draw_result",
			method: "GET",
			desc: "绘图结果",
		},
		collect: {
			url: "/addons/chatgpt/draw/draw_image_vote",
			method: "GET",
			desc: "点赞或收藏",
		},
		record: {
			url: "/addons/chatgpt/draw/user_draw",
			method: "GET",
			desc: "绘图记录",
		},
		atlas: {
			url: "/addons/chatgpt/draw/draw_images",
			method: "GET",
			desc: "展览图集",
		},
		keyword: {
			url: "/addons/chatgpt/draw/key_words",
			method: "GET",
			desc: "描述关键词",
		},
		template: {
			url: "/addons/chatgpt/draw/prompt_words",
			method: "GET",
			desc: "描述词模板",
		},
		matter: {
			url: "/addons/chatgpt/draw/attachment",
			method: "GET",
			desc: "上传的本地素材库",
		},
		picture: {
			url: "/addons/chatgpt/draw/img2img",
			method: "POST",
			desc: "以图生图",
		},
		//获取AI类型 /addons/chatgpt/draw/gptType
		type: {
			url: "/addons/chatgpt/draw/gptType",
			method: "GET",
			desc: "以图生图",
		},
		//渲染你选择角色后的默认值  参数 name根据获取AI类型名称来的 /addons/chatgpt/draw/gptTypePrompt
		prompt: {
			url: "/addons/chatgpt/draw/gptTypePrompt",
			method: "POST",
			desc: "以图生图",
		},
		text: {
			url: "/addons/chatgpt/draw/addUser",
			method: "POST",
			desc: "以图生图",
		},
		// addons/chatgpt/draw/addUserRole
		examine: {
			url: "/addons/chatgpt/draw/addUserRole",
			method: "POST",
			desc: "以图生图",
		},
		// 隐私协议  addons/chatgpt/index/richtext?id=2
		richtext: {
			url: "addons/chatgpt/index/richtext",
			method: "GET",
			desc: "隐私协议",
		},
		// 轮播图 addons/chatgpt/block/index
		block: {
			url: "addons/chatgpt/block/index",
			method: "GET",
			desc: "轮播图",
		},
		// 文章列表 addons/chatgpt/archives/index?flag=
		archives: {
			url: "addons/chatgpt/archives/index",
			method: "GET",
			desc: "文章列表",
		},
		// 文章详情 addons/chatgpt/archives/show?id=2
		show: {
			url: "addons/chatgpt/archives/show",
			method: "GET",
			desc: "文章列表",
		},
	},
};

// 组装接口路径
const getApiPath = (path) => {
	let apiArray = path.split("."),
		api = apiList;
	apiArray.forEach((v) => {
		api = api[v];
	});

	return api;
};

function http(path, data) {
	const api = getApiPath(path);
	!api && Promise.reject("接口未定义, 已阻止此次API请求");
	const method = api.method.toLowerCase();
	if (method != "get" && method != "post" && method != "fetch")
		Promise.reject(`${method} 请求方式未定义, 已阻止此次API请求`);
	return request[method](api.url, data, api.timeout);
}

export { apiList, http };
