<!-- 
	This is the default layout, used in sign-in and sign-up pages.
-->

<template>
	<div>
		<a-layout class="layout-default">
			<a-layout-content>
				<router-view />
			</a-layout-content>
		</a-layout>
	</div>
</template>

<script>
	export default {}
</script>

<style lang="scss" scoped>
	.layout-default .ant-layout-content {
		padding-top: 0;
	}
</style>
