import Vue from "vue";
import VueRouter from "vue-router";
import Redirect from "@/views/Redirect.vue";
import store from "@/store/index";

Vue.use(VueRouter);

let routes = [
	{
		// will match everything
		path: "*",
		component: () => import("../views/404.vue"),
		meta: {
			layoutClass: "layout-error",
		},
	},
	{
		path: "/",
		name: "Home",
		redirect: "/role",
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
		meta: {
			title: "首页",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/tables",
		name: "Tables",
		// layout: "dashboard",
		component: () => import("../views/Tables.vue"),
		meta: {
			title: "表格",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/test",
		name: "test",
		layout: "dashboard",
		component: () => import("../layouts/Default.vue"),
	},
	{
		path: "/billing",
		name: "Billing",
		layout: "dashboard",
		component: () => import("../views/Billing.vue"),
		meta: {
			title: "账单",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/rtl",
		name: "RTL",
		layout: "dashboard-rtl",
		component: () => import("../views/RTL.vue"),
		meta: {
			title: "右对齐",
			layoutClass: "dashboard-rtl",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/profile",
		name: "Profile",
		layout: "dashboard",
		component: () => import("../views/Profile.vue"),
		meta: {
			title: "用户信息",
			auth: true,
			layoutClass: "layout-profile",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/sign-in",
		name: "Sign-In",
		component: () => import("../views/Sign-In.vue"),
		meta: {
			title: "登录",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/sign-up",
		name: "Sign-Up",
		component: () => import("../views/Sign-Up.vue"),
		meta: {
			title: "注册",
			layoutClass: "layout-sign-up",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/write",
		name: "Write",
		layout: "dashboard",
		component: Redirect,
		meta: {
			title: "文案",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				component: () => import("../views/Write.vue"),
			},
			{
				path: "create",
				name: "WriteCreate",
				component: () => import("../views/WriteCreate.vue"),
				meta: {
					title: "生成",
					content: {
						keywords: "wow，wow ai,哇哦，AI角色",
						description:
							"Wow - 哇哦！创想我的美好世界",
					},
				},
			},
		],
	},
	{
		path: "/role",
		name: "Role",
		layout: "dashboard",
		component: Redirect,
		meta: {
			title: "首页",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				component: () => import("../views/Role.vue"),
			},
			{
				path: "chat",
				name: "RoleChat",
				component: () => import("../views/RoleChat.vue"),
				meta: {
					title: "聊天",
					content: {
						keywords: "wow，wow ai,哇哦，AI角色",
						description:
							"Wow - 哇哦！创想我的美好世界",
					},
				},
			},
		],
	},
	{
		path: "/drawing",
		name: "Drawing",
		component: Redirect,
		meta: {
			title: "Ai创作",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				layout: "dashboard",
				component: () => import("../views/Drawing.vue"),
				meta: {},
			},
			{
				path: "canvas",
				name: "DrawingCanvas",
				layout: "dashboard",
				component: () => import("../views/DrawingCanvas.vue"),
				meta: {
					title: "以文生图",
					content: {
						keywords: "wow，wow ai,哇哦，AI角色",
						description:
							"Wow - 哇哦！创想我的美好世界",
					},
				},
			},
			{
				path: "picture",
				name: "DrawingPicture",
				layout: "dashboard",
				component: () => import("../views/DrawingPicture.vue"),
				meta: {
					title: "以图生图",
					content: {
						keywords: "wow，wow ai,哇哦，AI角色",
						description:
							"Wow - 哇哦！创想我的美好世界",
					},
				},
			},
		],
	},
	{
		path: "/recharge",
		name: "Recharge",
		layout: "dashboard",
		component: () => import("../views/Recharge.vue"),
		meta: {
			title: "充值中心",
			auth: true,
			layoutClass: "layout-recharge",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/photo",
		name: "Photo",
		layout: "dashboard",
		component: () => import("../views/DrawingCenter.vue"),
		meta: {
			title: "Ai网红",
			layoutClass: "layout-photo",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
			keepAlive: true,
		},
	},
	{
		path: "/privacy",
		name: "privacy",
		component: () => import("../views/privacy.vue"),
		meta: {
			title: "隐私协议",
			layoutClass: "layout-agreement",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/identification",
		name: "identification",
		component: () => import("../views/identification.vue"),
		meta: {
			title: "角色协议",
			layoutClass: "layout-agreement",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},
	{
		path: "/newsInfo",
		name: "newsInfo",
		layout: "dashboard",
		component: Redirect,
		meta: {
			title: "新闻资讯",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				component: () => import("../views/newsInfo.vue"),
			},
		],
	},
	{
		path: "/groupChat",
		name: "groupChat",
		layout: "dashboard",
		component: Redirect,
		meta: {
			title: "交流群",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				component: () => import("../views/groupChat.vue"),
			},
		],
	},

	{
		path: "/newsArticles",
		name: "newsArticles",
		layout: "dashboard",
		component: () => import("../views/newsArticles.vue"),
		meta: {
			title: "新闻资讯",
			layoutClass: "layout-privacy",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
	},

	{
		path: "/lication",
		name: "lication",
		layout: "dashboard",
		component: Redirect,
		meta: {
			title: "应用",
			layoutClass: "layout-lication",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				component: () => import("../views/lication.vue"),
			},
		],
	},
	{
		path: "/friends",
		name: "friends",
		layout: "dashboard",
		component: Redirect,
		meta: {
			title: "朋友圈",
			auth: true,
			layoutClass: "layout-lication",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				component: () => import("../views/friends.vue"),
			},
		],
	},
	{
		path: "/send",
		name: "send",
		layout: "dashboard",
		component: Redirect,
		meta: {
			title: "发朋友圈",
			auth: true,
			layoutClass: "layout-lication",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				component: () => import("../views/send.vue"),
			},
		],
	},
	{
		path: "/equity",
		name: "equity",
		layout: "dashboard",
		component: Redirect,
		meta: {
			title: "免费权益",
			auth: true,
			layoutClass: "layout-lication",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				component: () => import("../views/equity.vue"),
			},
		],
	},
	{
		path: "/integration",
		name: "integration",
		layout: "dashboard",
		component: Redirect,
		meta: {
			title: "Ai集成",
			auth: true,
			layoutClass: "layout-lication",
			content: {
				keywords: "wow，wow ai,哇哦，AI角色",
				description:
					"Wow - 哇哦！创想我的美好世界",
			},
		},
		children: [
			{
				path: "/",
				component: () => import("../views/integration.vue"),
			},
		],
	},
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
	route.meta = route.meta || {};
	route.meta.layout = route.layout || parentLayout;

	if (route.children) {
		route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
	}
	return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
	mode: "hash",
	// mode: "history",
	base: process.env.BASE_URL,
	// base: '/',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth",
			};
		}
		return {
			x: 0,
			y: 0,
			behavior: "smooth",
		};
	},
});

router.beforeEach((to, from, next) => {
	if (to.meta.auth === true && !store.state.user.token) {
		store.commit("user/setLoginPopup", true);
	} else {
		next();
	}
	if (to.path == "/drawing") {
		console.log(2356);
		sessionStorage.clear();
	}
	next();
});

router.afterEach((to, from) => {});

export default router;
