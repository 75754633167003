import { http } from "@/api/api";
import router from "@/router";

export default {
	namespaced: true,
	state: {
		token: "",
		userInfo: "",
		loginPopup: false,
		statisticInfo: "",
		roleData: {},
	},
	getters: {
		token: (state) => state.token,
		userInfo: (state) => state.userInfo,
		loginPopup: (state) => state.loginPopup,
		statisticInfo: (state) => state.statisticInfo,
		roleData: (state) => state.roleData,
	},
	mutations: {
		setToken(state, token) {
			state.token = token;
		},
		removeToken(state) {
			state.token = "";
		},
		userInfo(state, data) {
			state.userInfo = data;
		},
		removeUserInfo(state) {
			state.userInfo = "";
		},
		setLoginPopup(state, value) {
			state.loginPopup = value;
		},
		statisticInfo(state, data) {
			state.statisticInfo = data;
		},
		setRoleData(state, data) {
			state.roleData = data;
		},
	},
	actions: {
		/**
		 * // 退出登录
		async logout({ commit }) {
			await new Promise(resolve => {
				http("login.logout")
				resolve()
			})
			commit("removeToken")
			commit("removeUserInfo")
			router.replace("/")
		},**/
		// 退出登录
		async logout({ commit }) {
			await new Promise((resolve) => {
				// http("common.logout")
				http("login.logout");
				resolve();
			});
			commit("removeToken");
			commit("removeUserInfo");
			router.replace("/");
		},
		// 获取用户信息
		async getUserInfo({ commit, dispatch, getters, state }, token = "") {
			const result = await new Promise((resolve, reject) => {
				token && commit("setToken", token);
				http("user.info")
					.then((res) => {
						if (res.code === 1) {
							commit("userInfo", res.data);
							resolve(res);
						} else {
							reject(res);
						}
					})
					.catch((err) => {
						reject(err);
					});
			});
			token && router.go(0);
			return result;
		},
		// // 获取角色信息
		// async getRoleData({ commit, dispatch, getters, state }, toke = "") {
		// 	const result = await new Promise((resolve, reject) => {
		// 		token && commit("setToken", token);
		// 		http("drawing.examine")
		// 			.then((res) => {
		// 				if (res.code == 1) {
		// 					res.data.data.length != 0 && commit("roleData", res.data.data[0]);
		// 					console.log(111, roleData);
		// 					resolve(res);
		// 				} else {
		// 					reject(res);
		// 				}
		// 			})
		// 			.catch((err) => {
		// 				reject(err);
		// 			});
		// 	});
		// 	return result;
		// },
		// 获取统计数据
		async getStatisticInfo({ commit, dispatch, getters, state }) {
			const result = await new Promise((resolve, reject) => {
				http("common.statistic")
					.then((res) => {
						if (res.code === 1) {
							commit("statisticInfo", res.data);
							resolve(res);
						} else {
							reject(res);
						}
					})
					.catch((err) => {
						reject(err);
					});
			});
			return result;
		},
	},
};
