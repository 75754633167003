import { http } from "@/api/api";

export default {
	namespaced: true,
	state: {
		config: "",
		share: "",

		ws_url: "",
	},
	getters: {
		config: (state) => state.config,
		share: (state) => state.config,

		ws_url: (state) => state,
	},
	mutations: {
		setConfig(state, data) {
			state.config = data;
		},
		setShare(state, data) {
			state.share = data;
		},

		setWsUrl(state, data) {
			state.ws_url = data;
		},
	},
	actions: {
		// 获取用户信息
		async getConfigInfo({ commit, dispatch, getters, state }) {
			const result = await new Promise((resolve, reject) => {
				http("common.init", { platform: "Web" })
					.then((res) => {
						if (res.code === 1) {
							document.title = res.data.system.name;
							commit("setConfig", res.data.system);
							commit("setShare", res.data.share);

							commit("setWsUrl", res.data.ws_url);
							resolve(res);
						} else {
							reject(res);
						}
					})
					.catch((err) => {
						reject(err);
					});
			});
			return result;
		},
	},
};
